import React from 'react';
import { useSomComponents } from './util';
import { toBigInt } from 'ethers';
import { Paper, Button, Box, Chip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {EntityAddress} from './components/EntityAddress';
import { TextField } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Alert } from '@mui/material';
import {getBalance, getNft, getNftsForOwner, getTokensForOwner, nftToImageUrl, nftToMml, nftToName} from "./alchemy";

// import markdown renderer
import ReactMarkdown from 'react-markdown';

export default function SomEntity({ entityAddress}) {


    const [components, loading, refresh] = useSomComponents(entityAddress);

    if (loading) {
        return <div>Loading...</div>;
    }

    const componentsOnEntity = components.filter(component => component.has);
    const componentsNotOnEntity = components.filter(component => !component.has);

    // if entity has a NameComponent, get its name
    const nameComponent = componentsOnEntity.find(component => component.name == "Name");
    const name = nameComponent && nameComponent.data.length > 0 ? nameComponent.data[0][1] : "Unnamed";

    // if entity has a MmlComponent, get its URL
    const mmlComponent = componentsOnEntity.find(component => component.name == "Mml");
    const mmlUrl = mmlComponent && mmlComponent.data.length > 0 ? mmlComponent.data[0][1] : null;

    const profileComponent = componentsOnEntity.find(component => component.name == "Profile");
    const profile = profileComponent && profileComponent.has && profileComponent.data;

    const imageComponent = componentsOnEntity.find(component => component.name == "Image");
    const image = imageComponent && imageComponent.has && imageComponent.data.length > 0 ? imageComponent.data[0][1] : null;

    const description = componentsOnEntity.map(component => component.text).join("\n\n");

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <h1>{name}</h1>
                    <ReactMarkdown>{description}</ReactMarkdown>
                    <EntityAddress entityAddress={entityAddress} />
                    <Box m={2}>
                        {<img src={`https://som1-service-oc7yefw67a-uc.a.run.app/svg/${entityAddress}`} width="100%" />}
                    </Box>

                    {/*{profile && <SomProfile profile={profile} />}*/}
                    <MmlView url={`https://som1-service-oc7yefw67a-uc.a.run.app/mml/${entityAddress}`} />
                </div>
                <div style={{ flex: 1 }}>
                    <Box>
                        <h3>Components</h3>
                        {componentsOnEntity.map(component => {
                            return <SomComponent onAction={refresh} key={component.name} component={component} entityAddress={entityAddress} />;
                        })}
                        <h3>Add new components</h3>
                        {componentsNotOnEntity.map(component => {
                            return <SomAddComponent onAction={refresh} key={component.name} component={component} entityAddress={entityAddress} />;
                        })}
                        <h3>Assets</h3>
                        <SomAssets entityAddress={entityAddress} />
                    </Box>
                </div>
            </div>
        </div>
    );
}

function SomComponent({ component, entityAddress, onAction }) {
    return (
        <Accordion p={2} m={2} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
                <b>{component.name}</b>
            </AccordionSummary>
            <AccordionDetails>
            <EntityAddress entityAddress={component.address} />

                <h3>State</h3>

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Key</TableCell>
                                <TableCell>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {component.data.map(([key, value]) => (
                                <TableRow key={key}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell><SomValue value={value}/></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <h3>Actions</h3>
                <ul>
                    {Object.keys(component.actions).map(actionName => {
                        return <SomAction key={actionName} onAction={onAction} abi={component.abi} entityAddress={entityAddress} actionName={actionName} actionFunction={component.actions[actionName]} />;
                    })}
                </ul>


            </AccordionDetails>

        </Accordion>
    );
}

function SomValue({ value }) {

    // check if address
    if (value.length === 42 && value.startsWith("0x")) {
        return <EntityAddress entityAddress={value} />;
    }

    if (typeof value === "string") {
        return value;
    }
    if (typeof value === "object") {
        return <div>{
            Object.keys(value).map((key, index) => (
            <div key={index}><b>{value[key]}</b>: <SomValue value={value[key]} /></div>
        ))}</div>;
    }
    if (typeof value === "bigint") {
        return value.toString();
    }
    return value;

}

function SomAddComponent({ component, entityAddress, onAction }) {
    return (
        <Accordion p={2} m={2} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
            >
               <b>{component.name}</b>
            </AccordionSummary>
            <AccordionDetails>
            <EntityAddress entityAddress={component.address} />
                <h3>Actions</h3>
                <ul>
                    {Object.keys(component.add).map(actionName => {
                        return <SomAction key={actionName} onAction={onAction} abi={component.abi} entityAddress={entityAddress} actionName={actionName} actionFunction={component.actions[actionName]} />;
                    })}
                </ul>
            </AccordionDetails>

        </Accordion>
    );
}

// an action function always starts with entityAddress and then the rest of the arguments.
// assumes one extra argument, as a string, for now
function SomAction({ entityAddress, actionName, abi, actionFunction, onAction }) {

    // find ABI for this action
    const actionAbi = abi.find(item => item.name === actionName);

    // get args past the first one, which is always entityAddress
    const actionArgs = actionAbi.inputs.slice(1);

    const [actionArgValues, setActionArgValues] = React.useState(Array(actionArgs.length).fill(""));

    const [actionError, setActionError] = React.useState(null);

    return (
        <div>
            <h4>{actionName}</h4>
            <div>
                {actionArgs.map((actionArgAbi, index) => {
                    return <SomActionArg key={index} actionArgAbi={actionArgAbi} getArg={() => actionArgValues[index]} setArg={value => setActionArgValues(prev => {
                        const newArgs = [...prev];
                        newArgs[index] = value;
                        return newArgs;
                    })} />
                })}
            </div>
            <Button variant={"outlined"} onClick={async () => {
                console.log("actionArgValues", actionArgValues)
                try {
                    const result = await actionFunction(entityAddress, ...actionArgValues);
                    await result.wait();
                        if (result.status === 0) {
                            console.log("Transaction failed", result);
                            return;
                        }
                        onAction();
                }
                catch (e) {
                    console.error((JSON.parse(JSON.stringify(e))));
                    setActionError(e.info.error.data.message);
                    setTimeout(() => {
                        setActionError(null);
                    }
                    , 10000);
                }

            }}>Run {actionName}</Button>
            {actionError && <Box mt={2}><Alert mt={2} severity="error">{actionError}</Alert></Box>}
        </div>
    );
};

function SomActionArg({ actionArgAbi, getArg, setArg }) {

    const argName = actionArgAbi.name;
    const argType = actionArgAbi.type;

    return (
        <Box mb={1}>
            <TextField
                label={argName}
                size='small'
                value={getArg()}
                onChange={e => setArg(e.target.value)}
            />
        </Box>
    );
}

function MmlView({ url }) {

    console.log("CALLING MMLVIEW", url)

    // return iframe
    return <iframe style={{
        border: "none",
        width: "100%",
        height: "500px"
    }} src={"https://mml-view.space?backgroundColor=black&url=" + url} allow="autoplay; fullscreen; microphone; camera" />;
}

function SomProfile({ profile }) {

    console.log("CALLING SOMPROFILE", profile)

    const [avatarNft, setAvatarNft] = React.useState(null);
    const [imageNft, setImageNft] = React.useState(null);
    const [nameNft, setNameNft] = React.useState(null);

    const profileAvatar = profile[0][1];
    const profileImage = profile[1][1];
    const profileName = profile[2][1];

    React.useEffect(() => {
        if(profileAvatar[0]) {
            nftToMml(profileAvatar[0], profileAvatar[1], profileAvatar[2]).then(result => {
                setAvatarNft(result);
            });
        }

        if(profileImage[0]) {
            nftToImageUrl(profileImage[0], profileImage[1], profileImage[2]).then(result => {
                setImageNft(result);
            });
        }

        if(profileName[0]) {
            nftToName(profileName[0], profileName[1], profileName[2]).then(result => {
                setNameNft(result);
            });
        }

    }, []);


    return (
        <Paper sx={{
            width: "300px",
        }}>
            {nameNft && <h3>{nameNft}</h3>}
            {imageNft && <img width="100%" src={imageNft} />}
            {avatarNft && <MmlView url={avatarNft}/>}
        </Paper>
    );
}

function SomAssets({ entityAddress }) {
    const [nfts, setNfts] = React.useState([]);
    const [tokens, setTokens] = React.useState([]);
    const [balance, setBalance] = React.useState(0);

    React.useEffect(() => {
        getNftsForOwner(entityAddress).then(result => {
            setNfts(result);
        });

        getTokensForOwner(entityAddress).then(result => {
            setTokens(result);
        });

        getBalance(entityAddress).then(result => {
            setBalance(result);
        });

    }, []);

    return (
        <Box sx={ {
                width: "300px",
            }}>
            <h4>NFTs</h4>
            {nfts.map(nft => {
                return <img width={100} src={nft.image.thumbnailUrl} />;
            })}

            <h4>Tokens</h4>
            {tokens.map(token => {
                // return <p>{JSON.stringify(token, "", " ")}</p>;
                return <img width={100} src={token.logo} />;
            })}

            <h4>Balance</h4>
            <p>{balance}ETH</p>
        </Box>
    );
}