import { Chip } from "@mui/material";

export function EntityAddress({entityAddress}) {

    if(entityAddress == "0x0000000000000000000000000000000000000000") {
        return <Chip label={"⛓️ Zero" } />;
    }

    return <Chip onClick={() => {
        // navigate to the entity
        window.location.href = "/entity/" + entityAddress;   
    }} label={"⛓️" + entityAddress} />;
}
