// import worker from public folder


export async function compileContract(contractCode) {
    const worker = new Worker('bundle.js.worker');

    worker.postMessage({
        contractCode,
    })

    return new Promise((resolve, reject) => {
        worker.addEventListener('message', function (e) {
            window.removeEventListener('message', this);
            resolve(e.data.output)
        }, false);
    }
    );
}
