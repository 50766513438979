import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './util.js';
import { compileContract } from './compiler.js';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Container } from '@mui/material';
import SomCompiler from './SomCompiler.js'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PersistentDrawerLeft from './PersistentDrawerLeft.js';

const router = createBrowserRouter([
  {
    path: "/entity/:address",
    element: <PersistentDrawerLeft content={<App />}/>,
  },
  {
    path: "/system",
    element: <PersistentDrawerLeft content={<SomCompiler />}/>
  }
]);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: [
      'Space Grotesk',
      'sans-serif'
    ].join(','),
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ThemeProvider theme={darkTheme}>
      <RouterProvider router={router} />
     </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


