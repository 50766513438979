
import { ethers } from 'ethers';
import { componentAbi } from './componentAbi';
import { useState, useEffect } from 'react';
import { componentRegistryAbi } from './componentRegistryAbi';
import compiler from './compiler';
import { abiComponentAbi } from './abiComponentAbi';
import { nameComponentAbi } from './nameComponentAbi';

// get metamask provider
export const provider = new ethers.BrowserProvider(window.ethereum);
export const signer = await provider.getSigner();

// request metamask switches to matic
// window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: '0x89' }] });

// // create signer from private key
const privateKey = "0xac0974bec39a17e36ba4a6b4d238ff944bacb478cbed5efcae784d7bf4f2ff80"
// export const provider = new ethers.JsonRpcProvider('http://localhost:8545');
// export const signer = new ethers.Wallet(privateKey, provider);


const componentRegistryAddress = "0x841eaF7fc6E360368BdD17D20Befc24cf7490b1b";
const abiComponentAddress = "0xae9B870436fd595e105518a050cD2F014BA8Acf9";
const nameComponentAddress = "0x999BF6C83DF46e71991425E0e60d417E94fD75a5";
const profileComponentAddress = "0xaCa1B678Be34d101A2B7D0b1c38294664E3b9dD5";

// REGISTRY 0x841eaF7fc6E360368BdD17D20Befc24cf7490b1b
// ENTITY 0x5FbDB2315678afecb367f032d93F642f64180aa3
// CHAIN 1337n
// ABI COMPONENT 0xae9B870436fd595e105518a050cD2F014BA8Acf9
// NAME COMPONENT 0x999BF6C83DF46e71991425E0e60d417E94fD75a5
// PROFILE COMPONENT 0xaCa1B678Be34d101A2B7D0b1c38294664E3b9dD5


export async function deployContract(bytecode, args) {
    const contract = new ethers.ContractFactory([], bytecode, signer);
    const deployedContract = await contract.deploy(...args);
    await deployedContract.waitForDeployment();

    console.log("deployedContract", deployedContract.target)
    return deployedContract;
}

export async function registerComponent(componentAddress) {
    const componentRegistry = new ethers.Contract(componentRegistryAddress, componentRegistryAbi.abi, signer);
    return componentRegistry.addComponent(componentAddress);
}

// react hook to use components
export function useSomComponents(entityAddress) {
    const [components, setComponents] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getSomComponents(entityAddress).then(components => {
            setComponents(components);
            setLoading(false);
        });
    }, [entityAddress]);

    const refresh = () => {
        setLoading(true);
        getSomComponents(entityAddress).then(components => {
            setComponents(components);
            setLoading(false);
        }
        );
    };

    return [components, loading, refresh];
}

export async function getSomComponents(entityAddress) {
    // get all components from component registry
    const componentRegistry = new ethers.Contract(componentRegistryAddress, componentRegistryAbi.abi, provider);
    const componentContractAddresses = await componentRegistry.getComponents();

    const components = await Promise.all(componentContractAddresses.map(async componentAddress => {
        const contractInstance = new ethers.Contract(componentAddress, componentAbi.abi, provider);
        return getSomComponent(componentAddress, entityAddress);
    }));
    const filteredComponents = components.filter(component => component !== undefined);

    return filteredComponents;
}

async function getComponentAbi(componentAddress) {
    const contractInstance = new ethers.Contract(abiComponentAddress, abiComponentAbi, provider);
    const abiUri = await contractInstance.value(componentAddress);
    const response = await fetch(abiUri);
    try {
        const json =  await response.json();
        return json;
    }
    catch (e) {
        console.error(e);
        return undefined;
    }
}

async function getComponentName(componentAddress) {
    const contractInstance = new ethers.Contract(nameComponentAddress, nameComponentAbi, provider);
    return contractInstance.value(componentAddress);
}

export async function getSomComponent(componentAddress, entityAddress) {
    const contractInstance = new ethers.Contract(componentAddress, componentAbi.abi, provider);

    //const abiUri = await contractInstance.abiUri();
    // const response = await fetch("https://corsproxy.io/?" + abiUri);
    // const abi = await response.json();
    const abi = await getComponentAbi(componentAddress);
    const name = await getComponentName(componentAddress);
    console.log("ABI", abi)
    if(!abi) return undefined;


    const viewFunctions = abi.filter(item => {
        return item.stateMutability === "view" && item.inputs.length === 1 && item.inputs[0].type === "address" && (item.inputs[0].name === "_entity" || item.inputs[0].name === "_component");
    }).filter(item => item.name !== "has" && item.name !== "text")

    const actionFunction = abi.filter(item => {
        return item.type === "function" && item.stateMutability !== "view" && item.inputs.length >= 1 && item.inputs[0].type === "address" && (item.inputs[0].name === "_entity" || item.inputs[0].name === "_component");
    });

    const addFunctions = actionFunction.filter(item => {
        return item.name == "add" || item.name == "register";
    })

    console.log("ADD", addFunctions)

    // turn action functions into callable functions
    const actionFunctions = {};
    actionFunction.forEach(element => {
        actionFunctions[element.name] = async function (entityAddress, ...args) {
            const contract = new ethers.Contract(componentAddress, abi, provider);
            const contractWithSigner = contract.connect(signer);
            return contractWithSigner[element.name](entityAddress, ...args);
        };
    });

    const contract = new ethers.Contract(componentAddress, abi, provider);
    const has = await contract.has(entityAddress);

    const data =  has ? await Promise.all(viewFunctions.map(element => {
        return contract[element.name](entityAddress).then(result => [element.name, result]);
    })) : [];

    return {
        name: name || "Unnamed Component",
        address: componentAddress,
        text: await contractInstance.text(entityAddress),
        actions: actionFunctions,
        add: actionFunctions,
        abi,
        has,
        data
    };
}
