import {ethers} from "ethers";


// This script demonstrates access to the NFT API via the Alchemy SDK.
import { Network, Alchemy } from "alchemy-sdk";
import {ownerWindow} from "@mui/material";

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
    apiKey: "cI38DGPTxix3euhNQ53P-K1ZfbqwV4Po", // Replace with your Alchemy API Key.
    network: Network.ETH_MAINNET, // Replace with your network.
};

const polygonSettings = {
    apiKey: "gV7uWATBc9K6FfuJpBw8-_BduOrcU_pS", // Replace with your Alchemy API Key.
    network: Network.MATIC_MAINNET, // Replace with your network.
};

const alchemy = new Alchemy(settings);
const polygonAlchemy = new Alchemy(polygonSettings);

// create default provider for ethereum mainnet
export const provider = new ethers.JsonRpcProvider("https://eth-mainnet.g.alchemy.com/v2/cI38DGPTxix3euhNQ53P-K1ZfbqwV4Po");

export async function getNft(chainId, contractAddress, tokenId) {
    // assume chainid is 1
    const contract = new ethers.Contract(contractAddress, ["function tokenURI(uint256) view returns (string)"], provider);
    const tokenUri = await contract.tokenURI(tokenId);

    if(tokenUri.startsWith("ipfs://")) {
        const ipfsHash = tokenUri.replace("ipfs://", "");
        const response = await fetch("https://ipfs.io/ipfs/" + ipfsHash);
        const result =  await response.json();
        return result;
    }

    const response = await fetch(tokenUri);
    const result = await response.json();
    return result;
}

export async function nftToImageUrl(chainId, contractAddress, tokenId) {
    const nft = await getNft(chainId, contractAddress, tokenId);
    const imageUri =  nft.image;
    if(imageUri.startsWith("ipfs://")) {
        return "https://ipfs.io/ipfs/" + imageUri.replace("ipfs://", "");
    }
    return imageUri;
}

export async function nftToMml(chainId, contractAddress, tokenId) {
    const nft = await getNft(chainId, contractAddress, tokenId);
    return nft.mml;
}

export async function nftToName(chainId, contractAddress, tokenId) {
    if(contractAddress.toLowerCase() === "0xd4416b13d2b3a9abae7acd5d6c2bbdbe25686401") {
        const url = "https://metadata.ens.domains/mainnet/0xd4416b13d2b3a9abae7acd5d6c2bbdbe25686401/" + tokenId.toString()
        const response = await fetch(url);
        const nft = await response.json();
        console.log("NFT NAME", nft)
        return nft.name;
    }

    const nft = await getNft(chainId, contractAddress, tokenId);
    return nft.name;
}

export async function getNftsForOwner(ownerAddress) {
    const nfts = await alchemy.nft.getNftsForOwner(ownerAddress);
    const polygonNfts = await polygonAlchemy.nft.getNftsForOwner(ownerAddress);
    return [...nfts.ownedNfts, ...polygonNfts.ownedNfts];
}

export async function getTokensForOwner(ownerAddress) {
    const tokens = await alchemy.core.getTokensForOwner(ownerAddress)
    const polygonTokens = await polygonAlchemy.core.getTokensForOwner(ownerAddress);

    return [...tokens.tokens, ...polygonTokens.tokens];
}
export async function getBalance(ownerAddress) {
    const balance = await alchemy.core.getBalance(ownerAddress);
    // convert to ETH
    return ethers.formatEther(balance.toString());
}

// Function to convert chainId, contractAddress, and tokenId to an address
function nftToAddress(_chainId, _contractAddress, _tokenId) {
    // Encode the parameters

    const coder = new ethers.AbiCoder();

    const encoded = coder.encode(
        ["uint256", "address", "uint256"],
        [_chainId, _contractAddress, _tokenId]
    );
    // Hash the encoded parameters
    const hash = ethers.keccak256(encoded);
    const address = ethers.getAddress(`0x${hash.slice(-40)}`);
    return address;
}
