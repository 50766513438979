import SomEntity from './SomEntity';
import { Container } from '@mui/material';
import  { useParams } from 'react-router-dom';

function App() {

  // get query parameter enttityAddress
  // const urlParams = new URLSearchParams(window.location.search);
  // const entityAddress = urlParams.get('address');

  let { address } = useParams();

  // if address is null, make a new address
  if (address == "new") {
    // generate new ethereum address using ethers6
    const ethers = require('ethers');
    const wallet = ethers.Wallet.createRandom();
    address = wallet.address;
  }
  // update the URL to reflect the address
  window.history.replaceState(null, "", "/entity/" + address);
  

  return (
      <SomEntity entityAddress={address} />
  );
}

export default App;
