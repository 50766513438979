export const componentAbi = {
    "_format": "hh-sol-artifact-1",
    "contractName": "IComponent",
    "sourceName": "contracts/IComponent.sol",
    "abi": [
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_entity",
            "type": "address"
          }
        ],
        "name": "ComponentAdded",
        "type": "event"
      },
      {
        "anonymous": false,
        "inputs": [
          {
            "indexed": true,
            "internalType": "address",
            "name": "_entity",
            "type": "address"
          }
        ],
        "name": "ComponentRemoved",
        "type": "event"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_entity",
            "type": "address"
          }
        ],
        "name": "has",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "_entity",
            "type": "address"
          }
        ],
        "name": "text",
        "outputs": [
          {
            "internalType": "string",
            "name": "",
            "type": "string"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
    "bytecode": "0x",
    "deployedBytecode": "0x",
    "linkReferences": {},
    "deployedLinkReferences": {}
  }
  