import React, { useState } from "react";
import { compileContract } from "./compiler";
import { deployContract, registerComponent } from "./util";
import Editor from '@monaco-editor/react';

const md5 = require("md5");


function onChange(newValue) {
  console.log("change", newValue);
}

export default function SomCompiler() {
    const [code, setCode] = useState("contract HelloWorld {  string public greet = \"Hello World!\";  }");

    const [contractName, setContractName] = useState("");


    async function uploadAbi(str){
        // calculate md5 hash of file
        // import md5

        // md5 hash string without md5 library
        const hash = md5(str);

        // check existence of hash on content addressed storage already
        const url = new URL("https://mml-avatar.space/upload/" + hash);

        const result = await fetch(url, {
            method: "HEAD",
        });

        // if (result.status === 404) {
        // console.log("NOT FOUND, UPLOADING", hash);

        const resp = await fetch(url, {
            method: "PUT",
            headers: {
                "content-type": "application/octet-stream",
            },
            body: str,
        });



        // console.log("UPLOAD COMPLETE");
        // } else {
        // console.log("ALREADY FOUND " + result.status);
        // }

        return url.href;
    }

    async function compile(code) {
        console.log("compiling", code)
        const compiled = await compileContract(code);
        console.log("YA DONE")

        console.log("compiled", compiled)

        const byteCode = compiled.contracts.contract[contractName].evm.bytecode.object;
        const abi = compiled.contracts.contract[contractName].abi;

        // upload abi to content addressed storage
        const abiUrl = await uploadAbi(JSON.stringify(abi));
        console.log("abi", abiUrl);



        //console.log("bytecode", compiled.contracts[0].evm.bytecode.object);
        deployContract(byteCode, []).then(deployedContract => {
            console.log("deployedContract", deployedContract.target)

            // register the component
            registerComponent(deployedContract.target).then(() => {
                console.log("registered");
               // refresh();
            });
        });
    }

    return (
        <div>
            {/* <textarea value={code} onChange={e => setCode(e.target.value)} /> */}
            <input value={contractName} onChange={e => setContractName(e.target.value)} />
            <button onClick={() => compile(code)}>Compile</button>
            <Editor 
    
            height="90vh" theme="vs-dark" language="sol" defaultLanguage="sol" value={code} onChange={(value, event) => setCode(value)} />;
        </div>
    );
}